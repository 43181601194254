import React from 'react'
import { NavLink } from 'react-router-dom'
import { Navbar, Nav } from 'react-bootstrap';
import logo from '../../assets/logo.png';
import { Config } from '../../config.js'
import '../../index.css'
import styles from '../../style.css.js'

class NavBar extends React.Component {
  render(){
      return (
      <Navbar collapseOnSelect bg="light" expand="xl">
          <Navbar.Brand>
            <NavLink exact to={"/"}>
              <img src={logo} width="100px" height="100px" alt=""></img>
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav>
              <NavItem path="/" name="Home"/>
              <NavItem path="/about" name="About" />

              {/* <NavItem path="/summercamps" name="Summer Camps" />
              <NavItem path="/summerselect" name="Summer Select" />
              <NavItem path="/summertraining" name="Summer Training" /> */}
              <NavItem path="/wintertraining" name="Winter Programs" />
              <NavItem path="/privatetraining" name="Private Training" />

              <NavItem path="/gallery" name="Gallery" />
            </Nav>
          </Navbar.Collapse>
          <div style={{flexWrap: 'nowrap'}}>
            <Navbar.Brand href={Config.INSTAGRAM_LINK}>
              <p className="fa fa-instagram fa-2x" style={styles.socialMediaIcons}></p>
            </Navbar.Brand>
            <Navbar.Brand href={Config.FACEBOOK_LINK}>
              <p className="fa fa-facebook fa-2x" style={styles.socialMediaIcons}></p>
            </Navbar.Brand>
            <Navbar.Brand href={Config.MAIL_LINK}>
              <p className="fa fa-envelope fa-2x" style={styles.socialMediaIcons}></p>
            </Navbar.Brand>
            </div>
      </Navbar>
      )
  }
}

const NavItem = props => {
  return (
    <Navbar.Brand>
      <NavLink exact to={props.path} className="nav-link" style={styles.navLinks}>{props.name}</NavLink>
    </Navbar.Brand>
  );
}

export default NavBar;

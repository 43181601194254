import React from 'react';
import { Jumbotron, Container } from 'react-bootstrap';
import Gallery from 'react-grid-gallery';
import IMAGES from '../shared/Images.js'
import '../../index.css'
import styles from '../../style.css.js'
import { Config } from '../../config.js'


/*
  Get images from img.js
  Need to figure out thumbnails - image-thumbnail module?
  Try integrating with existing gallery lib, otherwise look at react-image-gallery
  Cloudfront to handle performance issues? otherwise enable caching again
  Enabling caching means we'll have problems with updates as before - need to sort out versioning
*/

class Pictures extends React.Component {
  render(){
    return (
      <Jumbotron style={styles.jumbotron}>
      <h1 className="text-center" style={styles.h1}> Gallery </h1>
      <Container>
        <hr style={styles.hr} />
        <div style={{
                    display: "block",
                    minHeight: "1px",
                    width: "100%",
                    border: "1px solid" + Config.WHITE,
                    overflow: "auto"}}>
          <Gallery
              images={IMAGES}
              enableImageSelection={false}/>
        </div>
      </Container>
      </Jumbotron>
      );
  }
}

export default Pictures

import React from 'react';
import  { Redirect } from 'react-router-dom'
import { Jumbotron, Container } from 'react-bootstrap';
import ContactUs from './shared/ContactUs';
import styles from '../style.css.js'

/*
  Page to display result of Stripe payment
  Dynamically rendered, and also not available to those who try to hardcode the URL
*/

const Result = ({location}) => {
  if (location.state == null || !location.state.redirected){
    return <Redirect to={{
           pathname: '/error'
           }}/>
  }
  if (!location.state.error){
    return (
      <Jumbotron style={styles.jumbotron}>
        <h1 className="text-center" style={styles.h1}> Success </h1>
          <Container>
            <hr style={styles.hr} />
            <p style={styles.headlinep} className="text-center"> Registration completed. Linzi will be in touch </p>
          </Container>
      </Jumbotron>
    )
  }
  else{
    return (
      <Jumbotron style={styles.jumbotron}>
        <h1 className="text-center" style={styles.h1}> Error </h1>
          <Container>
            <hr style={styles.hr} />
            <p style={styles.headlinep} className="text-center"> There has been an error processing your payment.
              Please try again, or contact Linzi using the contact form below  </p>
          </Container>
          <ContactUs/>
      </Jumbotron>
    )
  }
}

export default Result;

import React from 'react';
import { Jumbotron, Container, Row, Col } from 'react-bootstrap';
import ContactUs from '../shared/ContactUs';
import linzi_headshot from '../../assets/linzi-headshot.jpg';
import image11 from '../../assets/image2.jpeg';
import '../../index.css'
import styles from '../../style.css.js'

/*
  Contains some 'about us' type information, with a picture of linzi and a gallery of camp flyers
*/

class About extends React.Component {

  render() {

    return (
      <Jumbotron style={styles.jumbotron}>
      <h1 className="text-center" style={styles.h1}> About Us </h1>
        <Container style={{marginBottom: '3%'}}>
        <hr style={styles.hr} />
        <Row>
            <Col sm={{ size: 5, offset: 1}}>
            <Container style={{display: 'block',textAlign: 'center'}}>
            <figure className="figure">
              <img src={linzi_headshot} style={{...styles.img, marginLeft: 'auto', marginRight: 'auto'}} className="rounded-circle img-fluid" alt=""/>
              <figcaption className="figure-caption" style={{marginTop: '5%'}}>Linzi McCormick, NJ14 Soccer Club Director and
                Girls Technical Director for Hunterdon Soccer Club
              </figcaption>
            </figure>
            </Container>

              <p style={styles.p}>
              NJ14 was founded in 2018 by Linzi McCormick.
              Our aim is to create a positive, challenging and enjoyable learning environment,
              that provides players the freedom to express and develop their natural ability.
              After moving to the USA in 2014 Linzi has worked in numerous clubs teaching children from all ages and abilities.
              </p>
              </Col>


            <Col sm={{ size: 5, offset: 1}}>
            <p style={styles.p}>
            Linzi played professionally in the UK throughout her youth and college.
            Soccer has always been a significant, fundamental and exciting part of her life.
            She is extremely passionate and knowledgeable and truly enjoys sharing her experiences with others, especially youth players.
            </p>
            <p style={styles.p}>
            NJ14 Soccer Club is a female club. We strongly feel that young females learn, understand and feel more comfortable in a female setting.
            After 5 years of coaching female youth soccer it has become apparent that girls respond positively and thrive in an all female environment.
            </p>
            <Container style={{display: 'block',textAlign: 'center'}}>

              <img src={image11} style={{
                display: 'block',
                maxWidth: '100%',
                height: 'auto'
              }} className="img-fluid" alt=""/>
              </Container>
            </Col>
          </Row>
        </Container>

        <ContactUs/>
      </Jumbotron>
    )
  }
}

export default About

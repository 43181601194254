import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import '../../index.css'
import styles from '../../style.css.js'

const Footer = () => (
  <Navbar bg="light" expand="sm">
    <Container style={{textAlign: 'center', display: 'block'}}>
      <a href="#/contactus" className="nav-link" style={styles.navLinks}> Contact Us </a>
    </Container>
  </Navbar>
)

export default Footer;

import NJ14SummerTeam2015 from '../../assets/2015NJ14SummerTeam.png';
import image1 from '../../assets/image1.jpg';
import image2 from '../../assets/image2.jpeg';
import image5 from '../../assets/image5.jpeg';
import image6 from '../../assets/image6.jpeg';
import image7 from '../../assets/image7.jpg';
import image8 from '../../assets/image8.jpg';
import image9 from '../../assets/image9.jpg';
import image10 from '../../assets/image10.jpg';
import image11 from '../../assets/image11.jpg';
import image12 from '../../assets/image12.jpg';
import image13 from '../../assets/image13.jpg';
import image14 from '../../assets/image14.jpg';
import beachblastchamps from '../../assets/2005beachblastchamps.jpg';
import NJ14courage from '../../assets/2005NJ14courage.jpg';
import beachblast2ndplace from '../../assets/2006beachblast2ndplace.jpg';
import NJ14Reign from '../../assets/2006NJ14Reign.jpg';
import beachblastsecondplace from '../../assets/200506beachblastsecondplace.jpg';
import Summerselect from '../../assets/Summerselect.jpg';

import { Config } from '../../config.js'

export const Images =
[{
  src: beachblastchamps,
  thumbnail: beachblastchamps,
  thumbnailWidth: 1440,
  thumbnailHeight:  1440,
  caption: "2005 Beach Blast Champs"
},
{
  src: NJ14courage,
  thumbnail: NJ14courage,
  thumbnailWidth: 2048,
  thumbnailHeight: 1536,
  caption: "2005 NJ14 Courage"
},
{
  src: beachblast2ndplace,
  thumbnail: beachblast2ndplace,
  thumbnailWidth: 937,
  thumbnailHeight: 720,
  caption: "2006 Beach Blast 2nd Place"
},
{
  src: NJ14Reign,
  thumbnail: NJ14Reign,
  thumbnailWidth: 4032,
  thumbnailHeight: 3024,
  caption: "2006 NJ14 Reign"
},
{
  src: beachblastsecondplace,
  thumbnail: beachblastsecondplace,
  thumbnailWidth: 1440,
  thumbnailHeight: 1440,
  caption: "2005/06 Beach Blast 2nd Place"
},
{
  src: Summerselect,
  thumbnail: Summerselect,
  thumbnailWidth: 1440,
  thumbnailHeight: 1440,
  caption: "Summer Select"
},
{
  src: image1,
  thumbnail: image1,
  thumbnailWidth: Config.IMAGE_1_WIDTH,
  thumbnailHeight:  Config.IMAGE_1_HEIGHT,
  caption: ""
},
{
  src: image2,
  thumbnail: image2,
  thumbnailWidth: Config.IMAGE_2_WIDTH,
  thumbnailHeight: Config.IMAGE_2_HEIGHT,
  caption: ""
},
{
  src: image5,
  thumbnail: image5,
  thumbnailWidth: Config.IMAGE_5_WIDTH,
  thumbnailHeight: Config.IMAGE_5_HEIGHT,
  caption: ""
},
{
  src: image6,
  thumbnail: image6,
  thumbnailWidth: Config.IMAGE_6_WIDTH,
  thumbnailHeight: Config.IMAGE_6_HEIGHT,
  caption: ""
},
{
  src: image7,
  thumbnail: image7,
  thumbnailWidth: Config.IMAGE_7_WIDTH,
  thumbnailHeight: Config.IMAGE_7_HEIGHT,
  caption: ""
},
{
  src: image8,
  thumbnail: image8,
  thumbnailWidth: Config.IMAGE_8_WIDTH,
  thumbnailHeight: Config.IMAGE_8_HEIGHT,
  caption: ""
},
{
  src: image9,
  thumbnail: image9,
  thumbnailWidth: Config.IMAGE_9_WIDTH,
  thumbnailHeight: Config.IMAGE_9_HEIGHT,
  caption: ""
},
{
  src: image10,
  thumbnail: image10,
  thumbnailWidth: Config.IMAGE_10_WIDTH,
  thumbnailHeight: Config.IMAGE_10_HEIGHT,
  caption: ""
},
{
  src: image11,
  thumbnail: image11,
  thumbnailWidth: Config.IMAGE_11_WIDTH,
  thumbnailHeight: Config.IMAGE_11_HEIGHT,
  caption: ""
},
{
  src: image12,
  thumbnail: image12,
  thumbnailWidth: Config.IMAGE_12_WIDTH,
  thumbnailHeight: Config.IMAGE_12_HEIGHT,
  caption: ""
},
{
  src: image13,
  thumbnail: image13,
  thumbnailWidth: Config.IMAGE_13_WIDTH,
  thumbnailHeight: Config.IMAGE_13_HEIGHT,
  caption: ""
},
{
  src: image14,
  thumbnail: image14,
  thumbnailWidth: Config.IMAGE_14_WIDTH,
  thumbnailHeight: Config.IMAGE_14_HEIGHT,
  caption: ""
},/*
{
  src: image15,
  thumbnail: image15,
  thumbnailWidth: Config.IMAGE_15_WIDTH/2,
  thumbnailHeight: Config.IMAGE_15_HEIGHT/2,
  caption: "After Rain (Jeshu John - designerspics.com)"
},*/
{
  src: NJ14SummerTeam2015,
  thumbnail: NJ14SummerTeam2015,
  thumbnailWidth: Config.NJ14SUMMERTEAM_WIDTH,
  thumbnailHeight: Config.NJ14SUMMERTEAM_HEIGHT,
  caption: ""
}]

export default Images;

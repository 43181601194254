import React, { Component } from 'react';
import NavBar from './shared/NavBar'
import Main from './Main'
import Footer from './shared/Footer'

class App extends Component {
  render() {
    return (
      <div>
        <NavBar />
        <Main />
        <Footer />
      </div>
    );
  }
}

export default App;

import React from 'react';
import { Jumbotron, Container, Row, Col } from 'react-bootstrap';
import $ from 'jquery';
import ReCAPTCHA from "react-google-recaptcha";

import '../../index.css';
import { Config } from '../../config.js';
import styles from '../../style.css.js';

/*
  Google ReCAPTCHA integrated form that posts to the ContactUs AWS Lambda
*/

class ContactUs extends React.Component {
  render(){
    return (
      <Jumbotron style={{...styles.jumbotron, background: Config.PURPLE}}>
      <h1 className="text-center" style={styles.h1}> Contact NJ14 </h1>
      <Container>
      <hr style={styles.hr} />
      <form name="contact" action={Config.CONTACT_US_ENDPOINT} method="POST" style={{marginBottom: '5%'}}>
      <Row>
        <Col>
          <FormBox htmlFor="email" style={styles.formLabel} text="Email address" type="email" />
        </Col>
        <Col>
          <FormBox htmlFor="subject" style={styles.formLabel} text="Subject" type="subject" />
        </Col>
      </Row>
         <div className="form-group">
           <label htmlFor="message" style={styles.formLabel}>Message</label>
           <textarea className="form-control"
             name="message" required/>
         </div>
         <ReCAPTCHA sitekey={Config.RECAPTCHA_KEY} onChange={this.onCaptchaPress} />
         <button type="submit" disabled value="contactform"
         className="btn btn-three pull-right" style={styles.button}>Send</button>
       </form>
       </Container>
      </Jumbotron>
    )
  }
  onCaptchaPress = (response) => {
    $(':input[type="submit"]').prop('disabled', false);
  }
}

const FormBox = ({ htmlFor, text, type }) => (
  <div className="form-group">
    <label htmlFor={htmlFor} style={styles.formLabel}>{text}</label>
    <input type={type} className="form-control"
      name={type} required/>
  </div>
)


export default ContactUs

import { Config } from './config.js'

const button =
{
  background: Config.WHITE,
  border: '1px solid',
  color: Config.VIOLET,
  float: 'left'
}

const hr =
{
  borderTop: '1px solid' + Config.GREY,
  marginTop: '2%',
  marginBottom: '4%',
  borderColor: Config.WHITE,
}

const midHr =
{
  borderTop: '1px solid' + Config.GREY,
  borderColor: Config.WHITE,
}

const p =
{
  fontFamily: Config.FONT,
  color: Config.WHITE,
  textAlign: 'justify',
}

const headlinep =
{
  fontFamily: Config.FONT,
  color: Config.WHITE,
  textAlign: 'center',
}

const h1 =
{
  color: Config.WHITE,
  fontFamily: Config.FONT,
  fontWeight: 'bold'
}

const img =
{
  display: 'block',
  maxWidth: '80%',
  height: 'auto'
}

const navLinks =
{
  fontSize: '1.2rem',
  fontFamily: Config.FONT,
  color: '#313131',
}

const jumbotron =
{
  marginTop: '1rem',
  marginLeft: '5%',
  marginRight: '5%',
  marginBottom: '1rem',
  background: `linear-gradient(to bottom, ${Config.PURPLE}, ${Config.LIGHT_PURPLE})`,
}

const formLabel =
{
  color: Config.WHITE,

}

const socialMediaIcons =
{
  backgroundColor: Config.WHITE,
  color: Config.VIOLET
}

const registerLink =
{
  marginTop: '5%',
  marginBottom: '5%',
  textAlign: 'center',
  color: Config.VIOLET,
  fontFamily: Config.FONT,
  fontWeight: 'bold',
  fontSize: '1.2rem',

}

const eventTitle =
{
  marginTop: '5%',
  marginBottom: '5%',
  fontFamily: Config.FONT,
  textAlign: 'center',
  color: Config.PURPLE,
  fontSize: '1.6rem',
}

const eventDescription =
{
  fontFamily: Config.FONT,
  fontSize: '1rem',
  color: Config.VIOLET
}

const link =
{
  color: Config.PINK,
  textAlign: 'center'
}

const flyer =
{
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '50%',
  height: '50%'
}

const registrationButtonCard =
{
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '5%',
  width: '40%',
  height: '40%'
}

export default { button, hr, midHr, p, link, headlinep, h1, img, navLinks, jumbotron, formLabel, socialMediaIcons, registerLink, eventTitle, eventDescription, flyer, registrationButtonCard }

import React from 'react'
import { Jumbotron, Container, Row, Col, Button } from 'react-bootstrap';
import styles from '../../../../style.css.js';
import TemplatePage from '../../../shared/TemplatePage';

/*
Do something here like the private training
- Have various options - one for 6 week, one for high school prep, one for individual or group training
*/

class SummerTraining extends React.Component {
  constructor() {
    super();
    this.state = {
      width:  window.innerWidth,
      sixweekprograms: false,
      highschoolprep: false
    }
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth})
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }
  showSixWeekPrograms = () => {
    this.setState({
        ...this.state,
        sixweekprograms: true,
        highschoolprep: false
    })
  }
  showHighSchoolPrep = () => {
    this.setState({
        ...this.state,
        sixweekprograms: false,
        highschoolprep: true
    })
  }
  render() {
    return (
      <Jumbotron style={styles.jumbotron}>  
      <Row style={{marginBottom: '1%'}}>
        <Col>
            <Button className="btn" style={{...styles.button, width: '50%', height: 'auto', marginLeft: '50%'}} onClick={this.showSixWeekPrograms.bind(null, true)}>6 Week Programs</Button>
        </Col>
        <Col>
            <Button className="btn" style={{...styles.button, width: '50%', height: 'auto', marginRight: '50%'}} onClick={this.showHighSchoolPrep.bind(null, false)}>High School Prep</Button>
        </Col>
      </Row>
        { this.state.sixweekprograms === false && this.state.highschoolprep === false && <Base/>}
        { this.state.sixweekprograms && <TemplatePage f='six-week-programs.json'></TemplatePage>}
        { this.state.highschoolprep && <TemplatePage f='high-school-prep.json'></TemplatePage>}
      </Jumbotron>
    )
  }
}

const Base = () => {
  const npmSummerTraining = [
    "With the current CDC guidelines as well as following the regulations set by the Governor we have designed our summer training programs to not only ensure social distancing but to maintain the highest level of safety measures needed to keep our players safe",
    "We are excited to get back on the field and continue where we left off. Our small training and individual training programs are designed to continue developing after our long break as well as being part of a group environment whilst improving skills, understanding and decision making. All training sessions are available to book from 6/22/20",
    "Location - Summer Road Park, Flemington, NJ",
    "All players who book more than 3 sessions will receive an NJ14 summer training jersey!",
  ]
  return (
    <Container>
        <h1 className="text-center" style={styles.h1}> Summer Training </h1>
          <Container>
            <ul style={{...styles.p, marginTop: '2%', listStyleType: "none" }}>
                {npmSummerTraining.map((text) => {
                    return <li className="text-center" style={{margin: '4%'}}>{text}</li>
                })}
            </ul>
            <hr style={styles.hr} />
          </Container>
    </Container>
  )
  
}


export default SummerTraining;

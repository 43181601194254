import React from 'react';
import { NavLink } from 'react-router-dom';
import { Jumbotron, Container, Row, Col } from 'react-bootstrap';
import '../../index.css'
import styles from '../../style.css.js'
import { Config } from '../../config.js'

class Home extends React.Component {
  render() {
    let data = require(`../../text/home.json`)
    let image = require(`../../assets/${data.image}`)

    return (
      <Jumbotron style={{...styles.jumbotron, background: Config.LIGHTGREY}}>
      <h1 className="text-center" style={{...styles.h1, color: Config.PURPLE}}> NJ14 Soccer Club  </h1>
        <img style={{marginTop: '3%'}} src={image} className="img-fluid" alt=""/>
        <Links/>
      </Jumbotron>
    )
  }
}

const Links = () => (
  <Container style={{marginTop: '3%'}}>
    
    <Row style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
      {/* <LinkButton path="summertraining" name="Summer Training" />
      <LinkButton path="summerselect" name="Summer Select" />
      <LinkButton path="summercamps" name="Summer Camps" /> */}
      <LinkButton path="wintertraining" name="Winter Programs" />
      <LinkButton path="privatetraining" name="Private Training" />
    </Row>

  </Container>
)

const LinkButton = ({ path, name }) => (
  <div style={{marginTop: '5%', display: 'table'}}>
    <NavLink exact to={path} className="btn form-control" style={{...styles.button, width: 'auto', height: 'auto', marginLeft: '25%', marginRight: '25%', background: Config.VIOLET, color: Config.WHITE, alignSelf: 'flex-start'}}>{name}</NavLink>
  </div>
)


export default Home;

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from './pages/Home';
import About from './pages/About';
import SummerSelect from './pages/Summer/SummerSelect';
import SummerCamps from './pages/Summer/SummerCamps';
import SummerTraining from './pages/Summer/Training/SummerTraining';
import SixWeekTraining from './pages/Summer/Training/SixWeekPrograms';
import HighSchoolPrep from './pages/Summer/Training/HighSchoolPrep';
import Gallery from './pages/Gallery';
import ContactUs from './shared/ContactUs';
import WinterTraining from './pages/Winter/WinterTraining';
import PrivateTraining from './pages/PrivateTraining';
import Form from './shared/Form';

import ErrorPage from './pages/ErrorPage'
import Result from './Result'

// The Main component renders one of the  provided routes.

function Main() {
  return (
    <main>
      <Switch>
        <Route exact path='/' component={Home}/>
        <Route exact path='/about' component={About}/>

        <Route exact path='/summerselect' component={SummerSelect}/>
        <Route exact path='/summertraining' component={SummerTraining}/>
        <Route exact path='/summercamps' component={SummerCamps}/>

        <Route exact path='/sixweektraining' component={SixWeekTraining}/>
        <Route exact path='/highschoolprep' component={HighSchoolPrep}/>

        <Route exact path='/wintertraining' component={WinterTraining}/>
        <Route exact path='/privatetraining' component={PrivateTraining}/>

        <Route exact path='/contactus' component={ContactUs}/>
        <Route exact path='/gallery' component={Gallery}/>

        <Route path='/:camp/registration' component={Form}/>

        <Route exact path='/result' component={Result}/>

        <Route component={ErrorPage} />
      </Switch>
    </main>
  );
}

export default Main;

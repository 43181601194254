import React from 'react';
import  { Redirect } from 'react-router'
import { Jumbotron, Container, Button, Row } from 'react-bootstrap';
import fetch from 'isomorphic-unfetch';
import StripeCheckout from 'react-stripe-checkout';

import '../../index.css'
import styles from '../../style.css.js';
import { Config } from '../../config.js'

/*
  Loads as {EVENTNAME}/registration
  Dynamically renders form fields
  Contains a contact form and Stripe payment button
  Successful Stripe requests will send the form data via ContactUs AWS Lambda
*/

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.onToken = this.onToken.bind(this);
        this.state = {
            title: this.props.location.title,
            submitSuccess: false,
            submitError: false,
            stripe: true,
            paid: this.props.location.cost
        }
    }

    async onToken(token) {
    // On a successful tokenization request,
    // POST to the Stripe lambda with the token and charge details
        try {
            const res = await fetch(Config.STRIPE_ENDPOINT, {
                method: 'POST',
                body: JSON.stringify({
                    token,
                    charge: {
                        amount: this.state.paid*100,
                        currency: Config.STRIPE_CURRENCY
                    },
                }),
            });
            await res.json();
            // format form data for the lambda
            let state = this.state;
            let formData = [];
                for (var key in state) {
                    if (state.hasOwnProperty(key)) {
                        formData.push(encodeURIComponent(key) + "=" + encodeURIComponent(state[key]))
                    }
                }
            formData = formData.join("&");

            // then send form details to contact form endpoint
            await fetch(Config.CONTACT_US_ENDPOINT, {
                mode: 'no-cors',
                method: 'POST',
                body: formData,
            });

            // update state to enable redirect to result page
            this.setState({ submitSuccess: true});
        }
        catch(error){
            this.setState({ submitError: true});
        }
    }

    render(){
        if (this.state.submitSuccess === true) {
            return <Redirect to={{
                        pathname: '/result',
                        state: { error: false, redirected: true }
                    }}/>
        }
        else if (this.state.submitError === true){
        return <Redirect to={{
                    pathname: "/result",
                    state: { error: true, redirected: true }
                }}/>
        }
        console.log(this.props)
        return (
        <Jumbotron style={{...styles.jumbotron, background: Config.PURPLE}}>
            <Container>
            <h1 className="text-center" style={styles.h1}> Register for {this.props.location.title} </h1>
                <hr style={styles.hr} />
                <form ref="form">
                    {
                        this.props.location.formDetails.map((detail) => (
                            <div className="form-group">
                                <label style={styles.formLabel}>{detail}</label>
                                <input className="form-control"
                                    value={this.state[detail]} onChange={this.handleDataChange.bind(this, detail)} required/>
                            </div>
                        ))
                    }
                    {   // custom payment option
                        this.props.location.custom ?
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                            <label className="text-center" style={styles.formLabel}> Amount to pay
                                <input type="number" className="form-control"
                                value={this.state.paid} onChange={this.handleDataChange.bind(this, 'paid')} required/>
                            </label>
                        </div>
                        : null
                    }
                </form>
                <div className="form-group">
                    <StripeCheckout
                        name={"NJ14"}
                        description={"Register for " + this.props.location.title}
                        stripeKey={Config.STRIPE_APIKEY}
                        amount={this.state.paid * 100}
                        token={this.onToken}
                        allowRememberMe={false}
                        email>
                        <Button
                            type="submit" style={{...styles.button, width: '50%', marginLeft: '25%', marginRight: '25%'}} className="form-control"
                            onClick={this.onSubmit}> Pay
                        </Button>
                    </StripeCheckout>
                </div>
            </Container>
        </Jumbotron>
    )
  }
    handleDataChange = (formType, event) => {
        this.setState({ [formType]: event.target.value});
    }
    onSubmit = (event) => {
        if (!this.refs.form.reportValidity()){
            event.stopPropagation();
        }
    }
}

export default Form;

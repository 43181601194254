export const Config = {
  CONTACT_US_ENDPOINT: "https://rsic867gz5.execute-api.us-east-1.amazonaws.com/dev/contact",
  RECAPTCHA_KEY: "6LcJso4UAAAAAKZVicsI43ShInaw0CRh0VvvcmkX",
    // Test key
  // STRIPE_APIKEY: "pk_test_KBgm6GnpASoDy9qkQuSmNLrs",
  STRIPE_APIKEY: "pk_live_kCtXUFvLkgrTLQ5fjNJsEVCZ",
  STRIPE_ENDPOINT: "https://8w02kr15y3.execute-api.us-east-1.amazonaws.com/production/stripe",

  STRIPE_CURRENCY: "USD",
  INSTAGRAM_LINK: "https://www.instagram.com/nj14_sc/",
  FACEBOOK_LINK: "https://www.facebook.com/NJ14Soccer/",
  MAIL_LINK: "mailto:linzi@nj14sc.com",

  RESIZE_WIDTH: 600,

  // Colours and fonts
  GREY: '#313131',
  LIGHTGREY: '#d2d9df',
  PINK: '#FF66B2',
  BLACK: '#000000',
  VIOLET: '#4E2766',
  PURPLE: '#330033',
  LIGHT_PURPLE: '#a0a',
  WHITE: '#f8f9fa',
  DARK_BLUE: '#272934',
  YELLOW: '#d3d4a5',
  LILAC: '#d7bbf0',
  FONT: 'Helvetica',

  // Image sizes and ratios
  IMAGE_1_WIDTH: 640,
  IMAGE_1_HEIGHT: 1136,
  IMAGE_2_WIDTH: 960,
  IMAGE_2_HEIGHT: 960,
  IMAGE_3_WIDTH: 640,
  IMAGE_3_HEIGHT: 1136,
  IMAGE_5_WIDTH: 640,
  IMAGE_5_HEIGHT: 640,
  IMAGE_6_WIDTH: 1136,
  IMAGE_6_HEIGHT: 852,
  IMAGE_7_WIDTH: 4032,
  IMAGE_7_HEIGHT: 3024,
  IMAGE_8_WIDTH: 4032,
  IMAGE_8_HEIGHT: 3024,
  IMAGE_9_WIDTH: 4032,
  IMAGE_9_HEIGHT: 3024,
  IMAGE_10_WIDTH: 750,
  IMAGE_10_HEIGHT: 740,
  IMAGE_11_WIDTH: 750,
  IMAGE_11_HEIGHT: 733,
  IMAGE_12_WIDTH: 1200,
  IMAGE_12_HEIGHT: 1600,
  IMAGE_13_WIDTH: 750,
  IMAGE_13_HEIGHT: 1334,
  IMAGE_14_WIDTH: 3018,
  IMAGE_14_HEIGHT: 3018,
  IMAGE_15_WIDTH: 873,
  IMAGE_15_HEIGHT: 911,
  NJ14SUMMERTEAM_WIDTH: 640,
  NJ14SUMMERTEAM_HEIGHT: 1136,
  GIRLS_SUMMER_SELECT_WIDTH: 695,
  GIRLS_SUMMER_SELECT_HEIGHT: 900,

  // Paths for image links for images in the home page carousel
  GIRLS_SUMMER_SELECT_REGISTRATION: "2019%20NJ14%20Girls%20Summer%20Program/registration",
}

import React from 'react';
import { Jumbotron, Container } from 'react-bootstrap';
import styles from '../../style.css.js'

/*
  Error page for bad paths that displays a dynamic error message
*/

const ErrorPage = ({location}) => {
  return (
    <Jumbotron style={styles.jumbotron}>
      <h1 className="text-center" style={styles.h1}> Oops </h1>
        <Container>
          <hr style={styles.hr} />
          <p style={styles.headlinep} className="text-center"> Nothing to see here </p>
        </Container>
    </Jumbotron>
  )
}

export default ErrorPage;

import React from 'react';
import TemplatePage from '../../shared/TemplatePage.js'

class SummerSelect extends React.Component {
  constructor() {
    super();
    this.state = {
      width:  window.innerWidth,
    }
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth})
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }
  render() {
    return (
      <TemplatePage f='summer-select.json'></TemplatePage>
    )
  }
}

export default SummerSelect;

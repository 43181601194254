import React from 'react'
import { NavLink } from 'react-router-dom'
import { Jumbotron, Container, Row, Col } from 'react-bootstrap';
import styles from '../../style.css'

const TemplatePage = ({f}) => {
    let data = require(`../../text/${f}`)
    let image = require(`../../assets/${data.image}`)
    return (
        <Jumbotron style={styles.jumbotron}>
            <h1 className="text-center" style={styles.h1}> {data.title} </h1>
            <Container>
                <hr style={styles.hr} />
                <Container>
                    <Row>
                        <Col lg={{ size: 5, offset: 1}}>
                            <Row>
                                <Container style={{display: 'block',textAlign: 'center'}}>
                                    <img src={image} style={{...styles.flyer, width: 'auto', height: 'auto'}} className="img-fluid" alt=""/>
                                </Container>
                                <Container style={{display: 'block',textAlign: 'center', marginTop: '2%'}}>
                                    {extraImage(data)}
                                </Container>
                            </Row>
                        </Col>
                        <Col lg={{ size: 5, offset: 1}}>
                            <Row>
                                <p style={{...styles.link, marginTop: '2%'}}>{data.header}</p>
                                <ul style={{...styles.p, marginTop: '2%', listStyleType: "none", padding: '0' }}>
                                    {data.text ? data.text.map((t) => {
                                        return <li style={{marginBottom: '2%'}}>{t}</li>
                                    }) : null}
                                </ul>
                            </Row>
                            <Row>
                                {data.registrationLink ?  reg(data.registrationLink) : null}
                            </Row>
                            <Row>
                                {data.navLink ?  nav(data.title, data.cost, data.formDetails, data.custom) : null}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>
      </Jumbotron>
    )
}

const nav = (title, cost, formDetails, custom) => {
    var path = `/${title}/registration`
    return <NavLink exact to={{ pathname: path, cost: cost, formDetails: formDetails, title: title, custom: custom }} name={title} className="btn form-control" style={styles.button}>Register and pay</NavLink>
}

const reg = (link) => {
    return <a href={link} className="btn form-control" style={{...styles.button, width: '50%', marginLeft: '25%', marginRight: '25%'}}>Register</a>
}

const extraImage = (data) => {
    if (data.extraImage != null) {  
        let image = require(`../../assets/${data.extraImage}`)
        return <img src={image} style={{...styles.flyer, width: 'auto', height: 'auto'}} className="img-fluid" alt=""/>
    }
}

export default TemplatePage;

import React from 'react';
import { Jumbotron, Container, Row, Col, Button } from 'react-bootstrap';
import '../../index.css'
import styles from '../../style.css.js'
import TemplatePage from '../shared/TemplatePage.js';

class PrivateTraining extends React.Component {
  constructor() {
    super();
    this.state = {
      width:  window.innerWidth,
      virtual: false,
      private: false
    }
  }
  showVirtual = () => {
      this.setState({
          ...this.state,
          virtual: true,
          private: false
      })
  }
  showPrivate = () => {
    this.setState({
        ...this.state,
        virtual: false,
        private: true
    })
}
  updateDimensions() {
    this.setState({ width: window.innerWidth})
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }
  render() {
    return (
      <Jumbotron style={styles.jumbotron}>  
      <Row style={{marginBottom: '1%'}}>
        <Col>
            <Button className="btn" style={{...styles.button, width: '50%', height: 'auto', marginLeft: '50%'}} onClick={this.showVirtual.bind(null, true)}>Virtual Training Sessions</Button>
        </Col>
        <Col>
            <Button className="btn" style={{...styles.button, width: '50%', height: 'auto', marginRight: '50%'}} onClick={this.showPrivate.bind(null, false)}>1 on 1 Private Sessions</Button>
        </Col>
      </Row>
        { this.state.private === false && this.state.virtual === false && <Base/>}
        { this.state.private && <TemplatePage f='private-sessions.json'></TemplatePage>}
        { this.state.virtual && <TemplatePage f='virtual-sessions.json'></TemplatePage>}
      </Jumbotron>
    )
  }
}

const Base = () => {
  return (
    <Container>
        <h1 className="text-center" style={styles.h1}> Private Sessions </h1>
    </Container>
  )
}

export default PrivateTraining;
